<template>
    <div class="lg:flex">
        <nav class="mb-8 lg:w-64 lg:mr-12 lg:mb-0">
            <div class="mb-6">
                <label for="search" class="sr-only">Search</label>

                <div class="relative">
                    <input
                        id="search"
                        v-model="query"
                        type="search"
                        name="search"
                        class="form-input pr-10"
                        placeholder="Search"
                    />

                    <search-icon class="absolute block w-4 h-auto right-4 align-y text-primary-500" />
                </div>
            </div>

            <div>
                <ul>
                    <li class="form-radio mb-2 last:mb-0">
                        <input id="category-all" v-model="category" type="radio" name="category" :value="null" />
                        <label for="category-all">All courses</label>
                    </li>

                    <li v-for="c in categories" :key="c.id" class="form-radio mb-2 last:mb-0">
                        <input :id="`category-${c.id}`" v-model="category" type="radio" name="category" :value="c.id" />
                        <label :for="`category-${c.id}`">{{ c.title }}</label>
                    </li>
                </ul>
            </div>
        </nav>

        <div class="flex-1">
            <ul>
                <li v-for="course in courses" :key="course.id" class="p-4 bg-white rounded-lg mb-4 last:mb-0">
                    <div class="flex justify-between">
                        <div
                            class="inline-block px-2 py-0.5 rounded-lg bg-primary-500 text-white text-sm font-semibold"
                            :style="{ 'background-color': course.courseType[0].colour }"
                        >
                            {{ course.courseType[0].title }}
                        </div>

                        <div class="text-sm">{{ course.courseCode }}</div>
                    </div>

                    <div class="mt-4">
                        <h2 class="h4">{{ course.title }}</h2>
                        <p v-if="course.rtoNumber">RTO #: {{ course.rtoNumber }}</p>

                        <div v-if="course.shortDescription" class="richtext mt-4" v-html="course.shortDescription" />

                        <table class="mt-4">
                            <tbody>
                                <tr v-for="duration in course.courseDuration" :key="duration.id">
                                    <td class="font-semibold pr-4">{{ duration.durationName }}:</td>
                                    <td>{{ duration.duration }}</td>
                                </tr>

                                <tr>
                                    <td class="font-semibold pr-4">Price:</td>
                                    <td v-if="course.coursePrice">${{ course.coursePrice }}</td>
                                    <td v-else>Enquire for pricing</td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="mt-4">
                            <a
                                v-if="course.courseInformation"
                                :href="course.courseInformation"
                                class="btn btn--primary"
                                target="_blank"
                                rel="nofollow noopener"
                            >
                                <info-icon />
                                <span>More information</span>
                            </a>

                            <a v-else :href="`/medical-industry-training-enquiry?course=${course.id}`" class="btn btn--primary">
                                <info-icon />
                                <span>Enquire</span>
                            </a>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
    import { ref, computed } from 'vue';
    import { useQuery } from '@vue/apollo-composable';
    import SearchIcon from '@/img/icons/magnifying-glass-solid.svg?component';
    import InfoIcon from '@/img/icons/circle-info-regular.svg?component';
    import { GET_COURSES_QUERY } from '@/js/graphql/courses';

    const props = defineProps({
        categories: {
            type: Array,
            required: true,
        },
    });

    const categories = ref(props.categories);

    const query = ref('');
    const category = ref(null);

    const { result } = useQuery(GET_COURSES_QUERY, () => {
        const variables = {};

        if (query.value) {
            variables.search = query.value;
        }

        if (category.value) {
            variables.category = category.value;
        } else {
            variables.category = props.categories.map(c => c.id);
        }

        return variables;
    }, {
        fetchPolicy: 'cache-and-network',
        keepPreviousResult: true,
    });

    const courses = computed(() => result.value?.coursesEntries ?? []);

</script>
