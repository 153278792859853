// CSS needs to be imported here as it's treated as a module
import '@/css/style.pcss';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';
import 'lazysizes/plugins/attrchange/ls.attrchange';

// ================================================
// Setup Vue3
// ================================================
import { createApp, ref, provide } from 'vue';
import { DefaultApolloClient } from '@vue/apollo-composable';
import apolloClient from './apollo';

// Swiper
import { Swiper, SwiperSlide } from 'swiper/vue';
import {
    Pagination,
    Navigation,
    Autoplay,
    Grid,
} from 'swiper/modules';

// Collapse transition
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue';

// Components
import Courses from './components/Courses.vue';
import MainMenu from './components/MainMenu.vue';
import Rot13Email from './components/Rot13Email.vue';

// Directives
import vClickOutside from 'click-outside-vue3';

const app = createApp({
    delimiters: [ '${', '}' ],

    setup() {
        provide(DefaultApolloClient, apolloClient);

        const swiperModules = ref([ Pagination, Navigation, Autoplay, Grid ]);
        const isMenuOpen = ref(false);

        const onToggleMenu = () => {
            isMenuOpen.value = !isMenuOpen.value;
        };

        return {
            swiperModules,
            isMenuOpen,
            onToggleMenu,
        };
    },
});

app.component('Courses', Courses);
app.component('MainMenu', MainMenu);
app.component('Rot13Email', Rot13Email);
app.component('CollapseTransition', CollapseTransition);
app.component('Swiper', Swiper);
app.component('SwiperSlide', SwiperSlide);

app.use(vClickOutside);

app.mount('#app');

document.addEventListener('onFormieLoaded', () => {
    document.querySelectorAll('[data-field-type="repeater"]').forEach(el => {
        const addButton = el.querySelector('.fui-repeater-add-btn');

        el.addEventListener('initRow', event => {
            const row = event.detail.$row;

            const removeButton = row.querySelector('.fui-repeater-remove-btn');

            let itemName = addButton.textContent.split(' ');
            if (itemName.length === 1) {
                return;
            }

            itemName = itemName[itemName.length - 1];

            removeButton.textContent = `Remove ${itemName}`;
        });

        const confirmText = el.getAttribute('data-add-confirm');

        if (confirmText) {
            addButton.addEventListener('click', event => {
                if (!confirm(confirmText)) {
                    event.preventDefault();
                    event.stopPropagation();
                }
            }, true);
        }
    });
});
