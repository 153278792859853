<template>
    <div class="main-menu" :class="{ 'main-menu--mounted': isMounted }">
        <div class="py-6">
            <div class="container">
                <div class="flex items-center max-lg:justify-between">
                    <a class="block mr-10" href="/">
                        <logo class="block w-auto h-12" />
                    </a>

                    <button type="button" class="menu" :class="{ 'menu--open': isMenuOpen }" @click.prevent="onToggleMenu">
                        <span class="sr-only">Toggle menu</span>

                        <span class="menu__wrapper">
                            <span class="menu__line" />
                            <span class="menu__line" />
                            <span class="menu__line" />
                        </span>
                    </button>
                </div>
            </div>
        </div>

        <div class="relative flex-1">
            <div ref="scrolling" class="absolute inset-0 overflow-y-auto">
                <div class="container">
                    <nav class="py-6">
                        <ul>
                            <li
                                v-for="(node, index) in nodes"
                                :key="node.id"
                                class="menu__node"
                                :style="{
                                    transitionDelay: `${index * 100}ms`,
                                }"
                            >
                                <span class="flex relative w-full justify-between items-center">
                                    <a v-if="node.attributes.href" v-bind="node.attributes" v-text="node.text" />
                                    <button v-else-if="node.children.length > 0" v-bind="node.attributes" type="button" @click="onSetNode(node.id)">{{ node.text }}</button>
                                    <span v-else v-bind="node.attributes">{{ node.text }}</span>

                                    <button v-if="node.children.length > 0" class="absolute right-0 align-y" type="button" @click="onSetNode(node.id)">
                                        <chevron-right
                                            class="block w-3 h-auto text-white transition-transform duration-300 ease-in-out"
                                            :class="{ 'transform rotate-90': activeNode === node.id }"
                                        />
                                    </button>
                                </span>

                                <collapse-transition>
                                    <ul v-if="node.children.length > 0 && activeNode === node.id" class="py-2">
                                        <li
                                            v-for="child in node.children"
                                            :key="child.id"
                                            class="menu__subnode"
                                        >
                                            <a v-bind="child.attributes" v-text="child.text" />
                                        </li>
                                    </ul>
                                </collapse-transition>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
    import Logo from '@/img/logo-white.svg?component';
    import ChevronRight from '@/img/icons/chevron-right-regular.svg?component';

    export default {
        components: {
            Logo,
            ChevronRight,
        },

        props: {
            nodes: {
                type: Array,
                required: true,
            },
            isMenuOpen: {
                type: Boolean,
                required: true,
            },
        },

        emits: [ 'close' ],

        data() {
            return {
                isMounted: false,
                activeNode: null,
            };
        },

        mounted() {
            disableBodyScroll(this.$refs.scrolling, {
                reserveScrollBarGap: true,
            });

            setTimeout(() => {
                this.isMounted = true;
            }, 100);
        },

        beforeUnmount() {
            enableBodyScroll(this.$refs.scrolling);
        },

        methods: {
            onToggleMenu() {
                this.$emit('close');
            },
            onSetNode(nodeId) {
                this.activeNode = this.activeNode === nodeId ? null : nodeId;
            },
        },
    };
</script>

<style>
    .main-menu {
        @apply flex flex-col fixed inset-0 bg-primary-500 text-white z-50;
    }

    .menu__node {
        @apply block opacity-0 mb-4 last:mb-0;
        @apply transition-all duration-500 ease-in-out;

        transform: translateY(-100%);
    }

    .menu__link {
        @apply text-base text-left text-white font-semibold block flex-1;
        @apply transition-colors duration-300 ease-in-out;
    }

    .menu__subnode {
        @apply pl-6 ml-1;
        @apply border-l-2 border-white/25;
    }

    .menu__subnode .menu__link {
        @apply py-1.5;
    }

    .main-menu--mounted .menu__node {
        @apply opacity-100;

        transform: translateY(0);
    }
</style>
