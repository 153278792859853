import gql from 'graphql-tag';

export const GET_COURSES_QUERY = gql`
    query GetCourses($category: [QueryArgument!], $search: String) {
        coursesEntries(courseType: $category, search: $search) {
            ... on courses_default_Entry {
                id
                title
                courseCode
                rtoNumber
                courseType {
                    id
                    title
                    
                    ... on courseTypes_Category {
                        colour
                    }
                }
                courseDuration {
                    ... on courseDurationBlock_Entry {
                        id
                        duration
                        durationName
                    }
                }
                coursePrice
                courseInformation
                shortDescription
            }
        }
    }
`;
